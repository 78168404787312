<!--
 * @Description: aishipgo 协议页
 * @Date: 2024-04-13 17:59:02
 * @LastEditTime: 2024-04-16 16:19:25
-->
<template>
  <div class="page protocol-page">
    <Viewheader :isHome="false" ref="navheader" />

    <section>
      <div class="protocol-wrap">
        <aside class="sidebar">
          <div class="nav-wrap">
            <h3 class="title">Aishipgo 协议</h3>

            <ul class="nav-list">
              <li
                v-for="item in protocolList"
                :key="item.id"
                :class="{ active: item.id === activeProtocol }"
                @click="changeProtocol(item.id)"
              >
                <span>{{ item.name }}</span>
              </li>
            </ul>
          </div>
        </aside>

        <div class="content">
          <header>
            <h3>{{ protocolList[activeProtocol - 1].name }}</h3>

            <div class="update-time">
              更新日期：{{ protocolList[activeProtocol - 1].updateTime }}
            </div>
          </header>

          <div class="main-text">
            <div
              class="part"
              v-for="(item, index) in protocolList[activeProtocol - 1]
                .contentList"
              :key="index"
              v-html="item.textHtml"
            ></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Viewheader from '../../components/viewheader/viewheader.vue';

// 协议内容
const ProtocolArtList = [
  {
    name: '隐私协议',
    id: 1,
    updateTime: '2024-03-15',
    contentList: [
      {
        textHtml: `
        <p>数贸（北京）科技有限公司（以下简称Aishipgo）承诺尊重使用Aishipgo产品和网站服务的个人隐私，Aishipgo根据现行法规及政策，制定本《隐私协议》。有时候Aishipgo需要某些信息才能为您提供您请求的服务，本《隐私协议》将详细说明Aishipgo在获取、管理、使用及保护用户个人信息方面的政策及措施。</p>
        <p>本《隐私协议》适用于从Aishipgo网站收集或向之提供的所有信息，Aishipgo网站不包括通过Aishipgo网络服务而接入的第三方服务（包括任何第三方网站）收集的信息。对该等第三方服务的隐私操作与政策不承担责任并且本声明不适用于该等服务。</p>
        <p>请您注意，本《隐私协议》仅适用于Aishipgo向Aishipgo网络服务用户所收集的信息，不适用于由 Aishipgo以任何其他方式（包括离线）收集的信息。</p>`,
      },
      {
        textHtml: `<h6>一、 收集您的信息</h6>
        <p>1. 您注册成为Aishipgo用户，以便于我们为您提供基础的资源服务。如果您仅需使用浏览Aishipgo资源，您不需要注册成为Aishipgo用户。</p>
        <p>2. 您明确了解并同意，在您注册、登陆Aishipgo账号或使用Aishipgo提供的服务时，Aishipgo可能将以多种方式（系统可能通过Cookie、Web Beacon或其他方式自动采集的技术信息）收集并记录您提供的相关个人信息，如：姓名、手机号码、您的用户名和密码、您的注册代码以及您选择向我们提供的其他信息。 记住您的身份及上述个人信息是您获得Aishipgo所提供服务的基础。</p>
        <p>3. 同时，基于优化用户体验、分析您使用Aishipgo服务的情况等目的，Aishipgo也可能获取与提升Aishipgo服务有关的其他信息，例如当您访问Aishipgo时，我们可能会收集不同服务的受欢迎程度、浏览器软件信息等信息，以便优化我们的服务。</p>
        <p>4. 您可以拒绝要您提供个人信息的请求。但是，如果您选择不提供信息，可能无法使用部分功能或服务。</p>
        `,
      },
      {
        textHtml: `<h6>二、 使用您的信息</h6>
        <p>5. 为了向您提供更好的服务或产品，Aishipgo会在下述情形使用您的个人信息：</p>
        <p>（1）根据相关法律法规的要求；</p>
        <p>（2）根据您的授权；</p>
        <p>（3）根据本声明及Aishipgo用户使用服务协议的约定。</p>
        <p>6. 未经您本人允许，Aishipgo不会向任何第三方披露您的个人信息，下列情形除外：</p>
        <p>（1）Aishipgo可能将您的个人信息与我们的关联公司、合作伙伴及第三方服务供应商、承包商及代理（例如代表我们发出电子邮件或推送通知的通讯服务提供商、为我们提供位置数据的地图服务供应商）分享（他们可能并非位于您所在的法域），以便向您提供Aishipgo的服务；</p>
        <p>（2）随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。我们将在转移前通知您；</p>
        <p>（3）Aishipgo已经取得您或您监护人的授权；</p>
        <p>（4）司法机关或行政机关给予法定程序要求Aishipgo披露；</p>
        <p>（5）Aishipgo为维护自身合法权益而向用户提起诉讼或仲裁时；</p>
        <p>（6）根据您与Aishipgo相关服务条款、用户使用服务协议的约定；</p>
        <p>（7）法律法规规定的其他情形。</p>
        `,
      },
      {
        textHtml: `<h6>三、 访问您的信息</h6>
        <p>完成Aishipgo的账号注册后，您登陆自己的Aishipgo账号后可以查阅或修改您提交给Aishipgo的个人信息。一般情况下，您可随时浏览、修改自己提交的信息，但出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法修改注册时提供的某些初始注册信息及验证信息。</p>
        `,
      },
      {
        textHtml: `<h6>四、 分享您的信息</h6>
        <p>7. Aishipgo的多项服务，可让您不仅与自己的社交网络，也与使用该服务的所有用户公开分享您的相关信息，例如，您在Aishipgo服务中所上传或发布的信息（包括您公开的个人信息）、您对其他人上传或发布的信息作出的回应，以及包括与这些信息有关的位置数据和日志信息。使用Aishipgo服务的其他用户也有可能分享与您有关的信息（包括位置数据和日志信息）。</p>
        <p>8. 因此，请您谨慎考虑通过Aishipgo服务传送、获取和交流的信息内容。在一些情况下，您可通过Aishipgo某些服务的隐私设定来控制有权浏览您共享信息的用户范围。如要求从Aishipgo的服务中删除您的相关信息，请通过该等特别服务条款提供的方式操作。</p>
        <p>9. 此外，为了向您提供最基本的产品功能，Aishipgo会依据相关法律法规和技术规范收集和使用您的部分个人信息，目前涉及隐私权限如下:</p>
        <p>&nbsp;&nbsp;【手机号、电子邮箱】:在用户注册时会取用用户的手机号、验证码、邮箱等信息，用于快速注册和账号找回等用途。如果存在任何账号争议问题,请及时联系我们。</p>
        <p>10. 同时，为了向您提供更丰富和完整的功能，Aishipgo可能嵌入了部分第三方SDK，您同意将由其直接收集和处理您的信息以确保功能的正常使用。该等SDK收集和处理信息等行为遵守相应SDK服务商自身的隐私条款。为了最大程度保障您的信息安全，我们建议您仔细查看并阅读其隐私条款。为保障您的合法权益，如您发现该等SDK或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与我们取得联系。</p>
        `,
      },
      {
        textHtml: `<h6>五、 对您信息的保护</h6>
        <p>11. Aishipgo将尽一切合理努力保护其获得的用户个人信息。为防止用户个人信息在意外的、未经授权的情况下被非法访问、复制、修改、传送、遗失、破坏、处理或使用，Aishipgo已经并将继续采取以下措施保护您的个人信息：</p>
        <p>（1）以适当的方式对用户的个人信息进行加密处理；</p>
        <p>（2）在适当的位置使用密码对用户个人信息进行保护；</p>
        <p>（3）限制陌生人对用户个人信息的访问；</p>
        <p>（4）其他的合理措施。</p>
        <p>12. 尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但Aishipgo仍然无法保证您的个人信息通过不安全途径进行交流时的安全性。因此，用户个人应采取积极措施以保证您的个人信息的安全，如：</p>
        <p>（1）定期修改账号密码；</p>
        <p>（2）不将自己的账号密码等个人信息透露给他人。</p>
        <p>13. 您已充分了解并同意：Aishipgo提供的个人信息保护措施仅适用于Aishipgo平台，一旦您离开Aishipgo，浏览或使用其他网站、服务及内容资源，Aishipgo即没有能力及义务保护您在Aishipgo以外的网站提交的任何个人信息，无论您登陆或浏览上述网站是否基于Aishipgo的链接或引导。</p>
        `,
      },
      {
        textHtml: `<h6>六、 对敏感个人信息的保护</h6>
        <p>14. 某些个人信息因其特殊性可能被认为是敏感个人信息，例如您的种族、宗教、个人健康和医疗信息等。相比其他个人信息，敏感个人信息受到更加严格的保护。请注意，您在使用Aishipgo时所提供、上传或发布的内容和信息（例如您社交活动的照片等信息），可能会泄露您的敏感个人信息。您需要谨慎地考虑，是否在使用Aishipgo时披露相关敏感个人信息。</p>
        <p>15. 您同意按本《隐私协议》所述的目的和方式来处理您的敏感个人信息。</p>
        `,
      },
      {
        textHtml: `<h6>七、 对未成年人个人信息的特别保护</h6>
        <p>16. Aishipgo非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用Aishipgo的服务前，应确保事先取得监护人的同意，如您在Aishipgo上申请注册账号，Aishipgo将默认为您已得到前述同意。</p>
        <p>17. Aishipgo将根据国家相关法律法规及本《隐私协议》的规定保护未成年人的个人信息。</p>
        `,
      },
      {
        textHtml: `<h6>八、 用户数据删除说明</h6>
        <p>个人用户若发现我司网络违反法律、行政法规的规定或者双方的约定收集、使用其个人信息的，可通过我司官方渠道联系删除其个人信息；发现我司收集、存储的其个人信息有错误的，可通过我司官方渠道联系予以更正。我司承诺采取必要措施予以删除或者更正。</p>
        `,
      },
      {
        textHtml: `<h6>九、 对Cookie及Web Beacon的使用</h6>
        <p>18. Cookie可以帮助网站辨认注册用户，计算用户数量，通常被各网站用来判定完成注册的用户是否已经实现登录。Aishipgo承诺，对cookie信息的研究仅用于提升服务/产品质量及优化用户体验之目的。</p>
        <p>19. 如您不希望个人信息保留在cookie中，您可以对浏览器进行配置：选择“拒绝cookie”或“当网站发送cookie时通知您”，您知道，鉴于Aishipgo的服务是通过支持cookie来是实现的，完成关闭cookie的操作后，可能影响到您访问Aishipgo或不能充分取得Aishipgo的服务。</p>
        <p>20. Aishipgo使用web beacon的方法和目的与cookie是相同的。</p>
        `,
      },
      {
        textHtml: `<h6>十、 隐私保护政策的修改</h6>
        <p>21. 根据我们的服务变动和用户的反馈，Aishipgo有权随时修改《隐私协议》的任何条款，一旦《隐私协议》的内容发生变动，Aishipgo将会直接在Aishipgo网站上公布修改之后的《隐私协议》，同时，我们将修订声明顶部的“最后更新日期”；该公布行为视为Aishipgo已经通知您修改内容。Aishipgo也可通过其他适当方式向用户提示修改内容。</p>
        <p>22. 如果您不同意Aishipgo对本《隐私协议》相关条款所做的修改，您有权停止使用Aishipgo服务。如果您继续使用Aishipgo服务，则视为您接受本公司对此协议相关条款所做的修改。</p>
        `,
      },
      {
        textHtml: `<h6>十一、 联系我们</h6>
        <p>我们设立了专门的个人信息保护负责团队，如果您对本隐私政策或个人信息保护相关事宜有任何疑问或投诉、建议时，您可以通将您的问题发送至<a style="cursor:pointer;text-decoration-skip:none;" class="link contextmenu-without-copyperm" href="mailto:support@dhgate.com" data-href="mailto%3Asupport%40dhgate.com" rel="noopener noreferrer" target="_blank" data-link-node="true"><span data-string="true">support@dhgate.com</span></a>，我们将尽快审核所涉问题，并在收到您的反馈后的5个工作日内予以回复。</p>
        `,
      },
    ],
  },

  {
    name: '用户协议',
    id: 2,
    updateTime: '2024-03-12',
    contentList: [
      {
        textHtml: `<h6>Aishipgo用户协议</h6>
        <h6>一、 服务条款的接受</h6>
        <p>(一) 本协议是用户与数贸（北京）科技有限公司（下称“数贸科技”）之间关于用户使用数贸科技提供的Aishipgo产品平台（以下简称Aishipgo平台）中产品服务所订立的协议。本服务协议构成您（无论个人或者单位）使用数贸科技所提供的产品服务之先决条件，除非您接受本协议条款，否则您无权使用本协议的相关服务。您的使用行为将视为同意接受本协议各项条款的约束。</p>
        <p>(二) 接受本协议意味着用户接受数贸科技“用户协议”及“隐私协议” 。</p>
        <p>(三) 请您仔细审阅并决定接受或不接受本协议，未成年人应在法定监护人陪同下审阅和履行，未成年人行使和履行本协议项下的权利和义务视为已获得了法定监护人的认可，您在享受数贸科技产品服务时必须完全、严格遵守本服务协议条款。</p>`,
      },
      {
        textHtml: `<h6>二、 服务说明</h6>
        <p>(一) 数贸科技产品服务涉及的产品及服务的所有权以及相关知识产权归数贸科技所有或数贸科技已获得相关权利人的合法授权。数贸科技所提供的服务将按照其服务条款和操作规则严格执行。</p>
        <p>(二) 数贸科技享有对用户使用本服务过程中的一切活动的监督、提示、检查的权利，如用户的行为违反有关法律法规或违反本协议条款的约定，数贸科技有权要求其纠正并追究其责任。</p>
        <p>(三) 数贸科技向用户提供的全部产品服务，均仅限于用户在数贸科技提供的平台使用，任何以恶意破解等手段将数贸科技提供的服务内容与数贸科技提供的平台分离的行为，均不属于本协议中约定的数贸科技提供的服务。由此引起的一切法律后果由行为人负责，数贸科技将依法追究行为人的法律责任。</p>
        <p>(四) 当用户使用数贸科技各单项服务时，用户的使用行为视为其对各单项服务的服务条款以及数贸科技在该单项服务中发出各类公告的同意。</p>
        <p>(五) 您应自行负责妥善且正确地保管、使用、维护您在数贸科技申请取得的账号、账号信息及账号密码。您应对账号信息和账号密码采取必要和有效的保密措施。非数贸科技原因致使您账号密码泄漏以及因您保管、使用、维护不当造成损失的，数贸科技无须承担与此有关的任何责任。同时，为了保护您的安全和隐私，请勿与他人共享账号，请勿将您的账号和密码告诉他人，否则数贸科技有权对异常使用的账号进行冻结。如遇此情况，请拨打客服电话解决，以保障自身隐私安全。</p>
        <p>(六) 数贸科技不对您因第三方的作为或不作为造成的损失承担任何责任，包括但不限于支付服务和网络接入服务、任意第三方的侵权行为。</p>
        `,
      },
      {
        textHtml: `<h6>三、 产品服务用户限制</h6>
        <p>(一) 用户权利 （内容详见 <a style="cursor:pointer;text-decoration-skip:none;" class="link contextmenu-without-copyperm" href="https://www.Aishipgo.com/" data-href="https%3A%2F%2Fwww.Aishipgo.com%2F" rel="noopener noreferrer" target="_blank" data-link-node="true"><span data-string="true">www.Aishipgo.com</span></a> ）</p>
        <p>(二) 限制</p>
        <p>1. 产品服务仅限于申请账号者自行使用；禁止赠与、出借、出租、转让或售卖，禁止使用机器人或者其他手段，批量非正常调用Aishipgo平台接口。否则数贸科技有权单方面取消该账号的服务资格，由此带来的损失由用户自行承担。数贸科技禁止用户私下有偿或无偿转让账号，以免因账号产生纠纷，用户应当自行承担因违反此要求而导致的任何损失，同时数贸科技保留追究上述行为人法律责任的权利。</p>
        <p>2. 若用户的行为违反本协议或违反国家相关法律法规，或数贸科技认为用户行为有损数贸科技或他人的声誉及利益，数贸科技公司有权取消该用户的资格而无须给与任何补偿，并有权追究用户该等行为给数贸科技造成的任何损失。</p>
        <p>3. 用户不得以盗窃、利用系统漏洞等非法途径获取或购买产品服务，也不得在未获数贸科技授权的销售平台购买产品服务，否则数贸科技有权取消用户的服务资格。由此引发的问题由用户自行承担，数贸科技不负任何责任，同时有权追究用户该等行为给数贸科技造成的任何损失。</p>
        <p>4. 任何用户不得使用及上传带有非法、淫秽、侮辱性或人身攻击意味的昵称和数据，一经发现，数贸科技有权取消其用户资格而无需给与任何补偿和退费，同时有权追究用户该等行为给数贸科技造成的任何损失。</p>
        <p>5. 被取消服务资格的用户，不能再参加由数贸科技组织的活动并不可再享有由数贸科技提供的各项优惠及增值服务，即不再享有用户权利。</p>
        <p>6. 对Aishipgo平台资源库中的会员专属模板/素材的授权使用请遵守 “版权声明”。</p>
        <p>7. 用户有权根据本协议购买情况获得相应的产品服务，但应在本协议约定范围内进行合理使用，用户在使用产品服务时应当依照本协议第四条第一款规定（内容详见数贸科技网站） 行使权利，并受该条款中的授权使用“限制”及“重要提示”约束，不得超出本协议所约定和所允许的使用范围及使用方式。禁止用户以下行为：</p>
        <p>8. 通过非法手段对付费用户账号的服务期限、消费金额、交易状态进行修改，或以非法方式或为了非法目的使用已购买的产品服务。</p>
        <p>9. 将您的数贸科技账号有偿或无偿提供给任何第三人，并允许他人通过您的账号使用产品服务。</p>
        <p>10. 将产品服务复制、出售、出租、授权给任何第三方，或将产品服务组合、编辑成PPT模板进行出售、出租。</p>
        <p>11. 主动对数贸科技用于保护产品服务的任何安全措施技术进行破解、更改、反向编译、破坏或其他篡改，或协助他人进行上述行为。</p>
        <p>12. 通过对Aishipgo产品目标程序进行反编译或任何反向操作以获取程序源代码或从中截取部分源码；或将反向后获取的源码或部分源码自行使用或许可任何第三方使用。</p>
        <p>13. 通过非数贸科技认可的方式获得产品服务，或者删除产品服务上的任何所有权声明或标签。</p>
        <p>14. 损害第三方利益，通过数贸科技收集第三方资料信息，破坏或盗取第三方账号，发送诈骗邮件和垃圾邮件等非法信息，侵犯第三方合法权益，包括但不限于隐私权、知识产权、财产权等。</p>
        `,
      },
      {
        textHtml: `<h6>五、 协议的变更及免责</h6>
        <p>(一) 数贸科技可能根据实际运营情况对本协议内容进行变更，如对产品服务费用收费模式、收费标准、方式等相关内容进行修改和变更，数贸科技也可能会对本服务目前提供的免费部分开始收费。前述协议变更前，数贸科技将在相应服务页面进行通知或公告，该等内容一经公布即视为通知会员。如您继续使用数贸科技提供的服务，则视为您已经接受本协议的全部修改。如您不同意条款修改，可主动向数贸科技提出终止产品服务，在您尚未使用已购买的产品服务内容的前提下，数贸科技将退还相应费用。</p>
        <p>(二) 产品服务提供的使用期限中包含数贸科技解决故障、服务器维修、调整、升级等所需用的合理时间，对上述情况所需用的时间数贸科技不予补偿。</p>
        <p>(三) 用户在使用数贸科技所提供的服务时，如因自身原因或第三方原因遭受任何人身或财务的损失、损害或伤害，数贸科技均不负责任。由于用户将个人密码告知他人或与他人共享注册账号，由此导致的任何个人资料泄露，数贸科技不负任何责任。</p>
        `,
      },
      {
        textHtml: `<h6>六、 服务的中断和终止</h6>
        <p>(一) 因发生数贸科技不可抗拒的事由，如政府行为、不可抗力、网络服务提供商等问题，导致产品服务无法继续，数贸科技将尽快通知用户，但不承担由此对用户造成的任何损失并不退还产品服务费。</p>
        <p>(二) 如用户违反或被视为违反本服务条款中的内容，数贸科技有权在不通知用户的情况下立即终止您已购买的所有服务，并取消您的数贸科技账号和使用权限，但不退还任何已缴纳的产品服务费用。</p>
        <p>(三) 在某些罕见情况下，由于权利授权问题可能致使某些服务无法使用。数贸科技将根据不同情况用服务内容替换方式给您补偿。如有需要，请联系客服。</p>
        <p>(四) 数贸科技未行使或延迟行使其在本协议项下的权利并不构成对这些权利的放弃，而单一或部分行使其在本协议项下的任何权利并不排斥任何其他权利的行使。数贸科技随时有权要求您继续履行义务并承担相应的法律责任。</p>
        `,
      },
      {
        textHtml: `<h6>七、 法律的适用和管辖</h6>
        <p>(一) 本服务条款的生效、履行、解释及争议的解决均适用中华人民共和国法律，本服务条款因与中华人民共和国现行法律相抵触而导致部分无效，不影响其他部分的效力。</p>
        <p>(二) 如就本协议内容或其执行发生任何争议，应尽量友好协商解决；协商不成时，任何一方均可向成都市高新区人民法院提起诉讼。</p>
        `,
      },
    ],
  },

  {
    name: 'AI服务协议',
    id: 3,
    updateTime: '2024-04-05',
    contentList: [
      {
        textHtml: `<h6>特别提示：</h6>
        <p>本协议系由用户与数贸（北京）科技有限公司（下文简称“Aishipgo”）之间就Aishipgo提供的平台产品和服务所订立的权利义务规范。</p>
        <p>本服务为Aishipgo平台产品及服务的组成部分，除非您阅读并点击同意本协议以及《用户协议》、《隐私协议》的所有条款，否则您无权登录Aishipgo服务账号（以下简称“账号”）及使用任何Aishipgo AI服务（以下简称“AI服务”）。您通过Aishipgo任一产品及服务平台登录、点击开启以及在开启本服务状态下进行内容上传、生成及复制转载等使用本服务的行为即视为您同意前述协议的全部条款，并同意受其约束。</p>
        <p>本协议中的重要条款，特别是免除、限制责任的条款，Aishipgo已采用加粗字体来特别提醒您，在使用本服务前请您认真查阅。</p>
        <p>本协议中您认为有违网络安全规范、涉及侵犯个人隐私及用户权益的，可以向网站客服进行投诉反馈。</p>
        `,
      },
      {
        textHtml: `<h6>使用规范</h6>
        <p>1. 本服务是Aishipgo基于技术模型服务而向自然人用户（以下简称“用户”）提供的AI合成服务，服务主要包括内容生成、合成、优化以及后续可能推出并纳入AI服务范畴内的其他各项服务，具体服务以我们实际提供的为准。 您在中国大陆地区以外使用本服务应遵守您所在地国家或地区的全部法律、法规及政策的限制与监督。</p>
        <p>2. 用户通过Aishipgo产品及服务平台（包括但不限于Aishipgo享有运营权利的官方应用软件、网站、H5页面、小程序、sdk、接口等，具体可使用平台以Aishipgo实际提供的为准）内置AI能力使用本服务，每个Aishipgo账号初始具备免费体验次数，具体次数以Aishipgo实际提供的为准。您应对通过账号使用本服务的一切行为（包括但不限于发布、生成、合成、优化信息、同意协议、规则等）承担全部责任；同时，您不得以任何形式转让、授权他人使用您的账号中所提供的本服务。Aishipgo后续可能变更服务方式，具体以实际提供的为准。</p>
        <p>3. 本服务仅可供您自行通过登录账号在Aishipgo产品及服务平台（包括但不限于Aishipgo享有运营权利的官方应用软件、网站、H5页面、小程序、sdk、接口等）中使用，您在任何时候均无权以任何方式将本服务包含的任何部分或全部予以转让、转授权、出租、借用、分享给您以外的任何一方。您访问或使用本服务的方式不应侵犯Aishipgo及任何第三方的权利。</p>
        <p>4. 您理解并同意，Aishipgo AI服务为收费服务，除Aishipgo免费赠送的体验次数外，后续使用本服务需要开通Aishipgo账号，收费标准以《隐私协议》的约定及Aishipgo产品及服务平台实时结算的付费金额为准。对于收费服务，Aishipgo会在您使用之前给予您明确的提示，只有您根据提示确认其愿意支付相关费用，您才能使用含本AI收费服务的账号服务；如您拒绝支付相关费用，则无法使用含本AI收费服务。除上述收费服务外，与相关网络服务有关的设备（如个人电脑、其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由您自行负担，Aishipgo不就如用户未支付该等费用导致无法接入本服务等影响服务使用的情形承担任何责任。</p>
        <p>5. 您在本服务中生成的人工智能生成内容所依赖的大数据和技术模型由Aishipgo提供。您充分知晓并理解，由于人工智能创作作品的法律法规仍在发展变化中，同时人工智能创作作品存在很大的不确定性，在使用模型输出结果前请您认真核实结果的真实性、可靠性等（同时若结果由模型输出，我们会在显著的位置进行注明），如您使用本服务遭受损失，Aishipgo将不承担任何责任。</p>
        `,
      },
      {
        textHtml: `<h6>内容限制</h6>
        <p>6. 您在使用本服务时输入的内容不得侵害他人权益（包括但不限于他人知识产权、肖像权、名誉权和个人隐私与其他权益），您应对使用本服务时自行输入的内容承担全部责任。</p>
        <p>7. 您同意，将严格依照本协议及《用户协议》、《隐私协议》使用本服务，并且不会利用本服务进行任何违法活动，输入或复制转载含有下列内容之一的信息：</p>
        <p>（1） 反对宪法所确定的基本原则的信息；</p>
        <p>（2） 违反国家规定的政治宣传信息；</p>
        <p>（3） 涉及国家秘密和/或安全、危害国家安全、颠覆国家政权、破坏国家统一的信息；</p>
        <p>（4） 损害国家荣誉和利益的信息；</p>
        <p>（5） 封建迷信、邪教和/或淫秽、色情、暴力、凶杀、恐怖的信息或教唆犯罪的信息；</p>
        <p>（6） 博彩有奖、赌博游戏、“私服”、“外挂”等非法出版信息；</p>
        <p>（7） 违反国家民族和宗教政策的信息，煽动民族仇恨、民族歧视、破坏民族团结的；</p>
        <p>（8） 妨碍区块链运行安全的信息；</p>
        <p>（9） 侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容；</p>
        <p>（10） 其他违反法律法规、部门规章、国家政策及公序良俗、社会公德等的内容。</p>
        <p>8. 您应当对使用本服务输入的内容是否符合法律法规规定进行必要审查，并由您承担由此产生的所有责任。您确保您提供的内容（包括但不限于自定义输入的文本或上传的图片等）不含政治相关及违反法律法规的内容，亦未侵害他人合法权益或已获得了充分、必要且有效的合法许可及授权。</p>
        <p>9. Aishipgo依据本协议、《用户协议》及《隐私协议》保护所有Aishipgo用户的个人隐私，但Aishipgo不就您使用本服务时输入的内容进行内容审查，因此您需自行确认使用本服务时输入的内容将不包含任何保密、敏感或个人隐私等信息，且您确认您的输入内容将不被认为是您的个人隐私资料。</p>
        `,
      },
      {
        textHtml: `<h6>使用限制</h6>
        <p>10. 您充分理解使用本服务的先决条件为注册并登录Aishipgo服务账号，您在注册及使用时应遵守《用户协议》中的注册及使用规则，妥善保管好您的账号、密码。</p>
        <p>11. 您发现本服务生成的内容违反“内容行为规范”的，有权在本服务内容生成页面上点击“内容举报/投诉”来进行投诉。</p>
        <p>12. 我们发现或收到他人举报或投诉用户违反本协议任何约定的，我们有权不经通知随时对相关内容进行处理，并视行为情节有权中止或终止提供相关服务，包括但不限于：无法使用本服务及账号封禁等。</p>
        <p>13. 本服务可能会对不同用户提供相同的反馈内容，您知悉并认可，您对这些内容并不享有独占权利（如著作权），您不得限制其他用户对相关反馈内容的使用。</p>
        `,
      },
      {
        textHtml: `<h6>免责条款</h6>
        <p>14. 您知悉并认可，由于本服务生成的所有内容均由人工智能模型生成，则：</p>
        <p>（1） 受限于现有技术水平，我们无法保证生成内容的准确性、完整性、时效性、可用性，生成内容亦不可避免的会出现事实、观点、价值等方面的瑕疵，我们对此不承担任何法律责任，但我们会尽可能督促第三方技术提供方为您提供稳定优质的技术服务。如果您在使用本服务的过程中遇到令您不快或与法律法规相冲突的情形，您可点击“内容举报/投诉”进行反馈，我们会在核实后立刻处理。</p>
        <p>（2） 生成内容仅供参考，不代表Aishipgo的态度、观点或立场，亦不构成Aishipgo对您的任何建议或意见。算法回答可能与您实际情况或现实生活不符，您应审慎判断其真实性、可靠性。</p>
        <p>15. 本服务所依赖的Aishipgo 技术模型输出结果其中有部分信息可能会存在一定的敏感性、不合理性或引发理解歧义，如您将生成的敏感性内容或不理性及有理解歧义的内容进行公开传播，您将独自承担相应的法律责任。</p>
        `,
      },
      {
        textHtml: `<h6>知识产权</h6>
        <p>16. 我们在本服务中提供的自有内容的知识产权属于Aishipgo所有，未经Aishipgo书面许可，您不得以任何形式使用或创造相关衍生作品。您亦不得通过任何机器人、“蜘蛛”等程序或设备来监视、复制Aishipgo平台及本服务提供的自有内容，或利用Aishipgo平台及本服务提供的自有内容申请知识产权登记、二次开发、开展竞争业务等。</p>
        <p>17. 您不得对本服务中的任何内容进行反向编译、反汇编等任何形式的反向工程或绕过任意服务的技术限制。对于任何计费机制（包括计量用户对服务使用情况的任何机制），您不得通过篡改或以其他方式尝试规避。</p>
        <p>18. 您理解并承诺，您在使用本服务时发布上传的内容（包括但不限于文字、图片、视频、音频等各种形式的内容及其中包含的音乐、视觉设计等所有组成部分，或相关数据）均由您原创或已获得相关权利人的合法授权（含转授权），不侵犯任何人的合法权益（包括但不限于知识产权、名誉权、姓名权、肖像权、隐私权、商业秘密等），否则由此产生的纠纷或风险由您自行承担。</p>
        <p>19. Aishipgo对本服务开发、运营等过程中产生的所有数据和信息等享有法律法规允许范围内的全部权利。</p>
        `,
      },
      {
        textHtml: `<h6>个人信息保护</h6>
        <p>Aishipgo非常重视保护用户信息。在您使用本服务的过程中，我们会收集和使用您的账号信息及操作行为信息（包括但不限于输入内容、点击“内容举报/投诉”等），用于向您提供AI服务；如我们共享您的个人信息至合作伙伴，除严格遵守“合法正当、最小必要、目的明确原则”外，我们亦将从技术保护措施、合作协议层面，严格要求合作伙伴履行对您个人信息的保护义务与责任。</p>
        `,
      },
      {
        textHtml: `<h6>违约责任</h6>
        <p>用户同意保障和维护Aishipgo及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给Aishipgo或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。</p>
        `,
      },
      {
        textHtml: `<h6>争议管辖</h6>
        <p>20. 本服务条款的生效、履行、解释及争议的解决均适用中华人民共和国法律，本服务条款因与中华人民共和国现行法律相抵触而导致部分无效，不影响其他部分的效力。</p>
        <p>21. 如就本协议内容或其执行发生任何争议，应尽量友好协商解决；协商不成时任何一方均可向成都市高新区人民法院提起诉讼。</p>
        `,
      },
      {
        textHtml: `<h6>协议效力</h6>
        <p>22. 本协议是您与我们之间关于您使用本服务所达成的法律协议，本协议生效日期以协议首部载明的更新日期为准。与此同时，本协议自您勾选“同意”（具体措辞以页面载明的为准）后对双方产生约束力，具体时间以我们系统记录的时间为准。</p>
        <p>23. Aishipgo可能根据实际运营情况对本协议内容进行变更，Aishipgo也可能会对本服务目前提供的免费部分开始收费，前述协议变更前，Aishipgo将在相应服务页面进行通知或公告，该等内容一经公布即视为通知用户。如您继续使用Aishipgo提供的服务，则视为您已经接受本协议的全部修改。如您不同意条款修改，则无权使用本协议的相关内容。</p>
        <p>24. 本协议视为《用户协议》、《隐私协议》的补充协议，是其不可分割的组成部分，与其构成统一整体，本协议未涉及的内容以《用户协议》、《隐私协议》为准，《用户协议》、《隐私协议》未涉及的内容以及本协议内容与《用户协议》、《隐私协议》内容存在冲突、不同的，以本协议的最新的更新版本（如有）的约定为准。</p>
        `,
      },
    ],
  },
];

export default {
  name: 'Protocol',

  components: { Viewheader },

  data() {
    return {
      protocolList: ProtocolArtList,

      activeProtocol: 1,
    };
  },

  methods: {
    // 切换协议
    changeProtocol(id) {
      this.activeProtocol = id;
    },
  },

  created() {
    const active = this.$route.query.active ?? null;

    if (active) {
      this.activeProtocol = active;
    }
  },

  computed: {},

  watch: {},

  filters: {},
};
</script>

<style lang="less" scoped>
.protocol-page {
  section {
    box-sizing: border-box;
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;

    background-color: #f0f2f5;
  }

  .protocol-wrap {
    width: 1280px;
    padding-top: 16px;
    display: flex;
    gap: 24px;
  }

  .sidebar {
    flex: 0 0 228px;
    height: 100%;
    overflow-y: auto;

    .nav-wrap {
      display: flex;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 32px;
      flex-direction: column;
      gap: 22px;
      background: #ffffff;

      .title {
        line-height: 32px;
        font-size: 22px;
        font-weight: 500;
        color: #1d2129;
      }

      .nav-list {
        display: flex;
        flex-direction: column;
        gap: 22px;

        li {
          line-height: 22px;
          font-size: 16px;
          font-weight: 500;
          color: #4e5969;
          position: relative;
          padding-left: 20px;

          span {
            cursor: pointer;
          }

          &.active {
            color: #1d2129;
            font-weight: 500;
          }

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 4px;
            height: 4px;
            margin-top: -2px;
            background-color: #4e5969;
            border-radius: 50%;
          }

          &.active::before {
            background-color: #1d2129;
          }
        }
      }
    }
  }
}
</style>

<style lang="less">
.protocol-page > section > .protocol-wrap {
  .content {
    flex: 1;
    overflow-y: auto;
    height: calc(100% -16px);

    background-color: #fff;
    border-radius: 8px;

    header {
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      border-bottom: 1px solid #f0f2f5;
      gap: 12px;

      h3 {
        margin-top: 32px;
        margin-left: 32px;
        font-weight: 500;
        line-height: 40px;
        font-size: 28px;
        color: #1d2129;
      }

      .update-time {
        margin-left: 32px;
        margin-bottom: 32px;
        line-height: 22px;
        font-size: 16px;
        color: #86909c;
      }
    }

    .main-text {
      padding: 28px 32px 0;

      .part:not(:last-child) {
        margin-bottom: 44px;
      }

      .part {
        p {
          line-height: 28px;
          font-size: 16px;
          color: #1d2129;
          margin-bottom: 20px;
        }

        h6 {
          line-height: 32px;
          font-size: 22px;
          font-weight: 500;
          color: #1d2129;
          margin-bottom: 12px;
        }
      }
    }
  }
}
</style>
